

.actions {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;

	.btn {
		margin-right: 10px;
		width: 100%;

		&:last-child {
			margin-right: 0;
		}
	}
	
}
